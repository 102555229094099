import { generateUrlParams } from "../../utils/generateUrlParams";

const baseUrl = "AdmissionList";

//global admission list search
export const getSearchAdmissionList = (filter) =>
	`${baseUrl}/searchAdmissionList?${generateUrlParams(filter)}`;
//an object containing all the params and their values is passed to the generateUrlParams function
export const getAdmissionList = (filter) =>
	`${baseUrl}/get-dept-admission-list?${generateUrlParams(filter)}`;

export const getClearanceInfoUrl = (filter) =>
	`${baseUrl}/clearanceInfo?${generateUrlParams(filter)}`;
export const updateClearanceStatusUrl = ({ refCode }) =>
	`${baseUrl}/update-clearance-status?refCode=${refCode}`;

export const downloadAdmissionsListTemplateUrl = () =>
	`${baseUrl}/DownloadSampleSheet`;
export const bulkUploadAdmissionListUrl = () =>
	`${baseUrl}/uploadAdmissionList`;
export const singleUploadAdmissionListUrl = () =>
	`${baseUrl}/uploadAdmissionRecord`;
export const updateAdmissionListRecordUrl = () =>
	`${baseUrl}/updateAdmissionListRecord`;
export const deleteAdmissionListRecordUrl = () =>
	`${baseUrl}/AdmissionListRecord`;
export const deleteAdmissionListUrl = () => `${baseUrl}/delete-admission-list`;
export const getAdmissionListReportsUrl = (filter) =>
	`${baseUrl}/admission-list-report?${generateUrlParams(filter)}`;
export const downloadAdmissionListReportsUrl = (filter) =>
	`${baseUrl}/download-admission-list-report?${generateUrlParams(filter)}`;
