import { generateUrlParams } from "../../utils/generateUrlParams";

const baseUrl = "Menu";

export const getMenusUrl = (filter) =>
	`${baseUrl}/menu?${generateUrlParams(filter)}`;

export const addMenuUrl = (filter) =>
	`${baseUrl}/create-menu?${generateUrlParams(filter)}`;

export const deleteMenuUrl = (menuId) => `${baseUrl}/${menuId}`;

export const updateMenuUrl = (filter) =>
	`${baseUrl}/update-menu?${generateUrlParams(filter)}`;

// export const getAllClaimsUrl = () => `${baseUrl}/claims`;

// export const getRoleClaimsUrl = (filter) =>
// 	`${baseUrl}/role-claims?${generateUrlParams(filter)}`;
