import { generateUrlParams } from "../../utils/generateUrlParams";

const baseUrl = "SundryPayment";

export const getApplicantInfoForSundryUrl = ({ regNo, subCategoryId }) =>
	`${baseUrl}/applicant-info?regNo=${regNo}&subCategoryId=${subCategoryId}`;
export const getApplicantSundryInvoicesUrl = (filter) =>
	`${baseUrl}/get-all-invoices?${generateUrlParams(filter)}`;
export const getSetUpCategoriesUrl = () => `${baseUrl}/setup-categories`;
export const getSetUpCategoryTypesUrl = ({ setupCategoryId }) =>
	`${baseUrl}/setup-category-types?setupCategoryId=${setupCategoryId}`;
export const getSetUpCategoryFeesUrl = ({ setupCategoryTypeId }) =>
	`${baseUrl}/setup-category-fees?setupCategoryTypeId=${setupCategoryTypeId}`;
export const getFeesForSundryPaymentUrl = ({
	setupCategoryTypeId,
	setupCategoryFeeId,
	deptId
}) =>
	`${baseUrl}/get-fee/${setupCategoryTypeId}/${setupCategoryFeeId}/${deptId}`;
export const getTranscriptPostingUrl = () => `${baseUrl}/transcript-posting`;
export const generateTranscriptInvoiceUrl = () =>
	`${baseUrl}/generate-transcript-app-invoice`;
export const getTranscriptBaseFeeeUrl = () =>
	`${baseUrl}/transcript-setup-category-fee`;
export const generateAllSundryInvoiceUrl = () => `${baseUrl}/generate-invoice`;
export const getSundryReportsUrl = (filter) =>
	`${baseUrl}/get-sundry-report?${generateUrlParams(filter)}`;
export const downloadSundryReportUrl = (filter) =>
	`${baseUrl}/download-sundry-report?${generateUrlParams(filter)}`;
export const getApplicationSundryInvoiceDetailsUrl = (rrr) =>
	`${baseUrl}/invoice-details?rrr=${rrr}`;

export const deactivateApplicationSundryInvoiceUrl = (rrr) =>
	`${baseUrl}/deactivate-sundry-invoice?rrr=${rrr}`;

export const getSundryPaymentBusaryFees = (filter) =>
	`${baseUrl}/all-busary-fees?${generateUrlParams(filter)}`;

export const createBusaryFeesUrl = () => `${baseUrl}/create-busary-fee`;

export const updateBusaryFeesUrl = (filter) =>
	`${baseUrl}/update-busary-fee?${generateUrlParams(filter)}`;

export const toggleBusaryFeesUrl = (filter) =>
	`${baseUrl}/toggle-busary-fee-status?${generateUrlParams(filter)}`;

export const deleteBusaryFeesUrl = (filter) =>
	`${baseUrl}/delete-busary-fee?${generateUrlParams(filter)}`;
