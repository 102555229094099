import { generateUrlParams } from "../../utils/generateUrlParams";

const studentBaseUrl = "studentProfile";
export const createStudentProfileUrl = () =>
	`${studentBaseUrl}/create-student-profile`;
export const getStudentProfileUrl = ({ refCode = false }) =>
	`${studentBaseUrl}/get-student-profile${
		refCode ? `?&refCode=${refCode}` : ""
	}`;
export const getInitialStudentProfileUrl = (jambRegNumber) =>
	`${studentBaseUrl}/GetInitialStudentProfile?JambRegNumber=${jambRegNumber}`;
export const updateStudentProfileUrl = ({ refCode = false }) =>
	`${studentBaseUrl}/UpdateStudentProfile${
		refCode ? `?&refCode=${refCode}` : ""
	}`;
export const getStudentsUrl = (filter) =>
	`${studentBaseUrl}/studentRecords?${generateUrlParams(filter)}`;
export const getAllStudentsUrl = (filter) =>
	`${studentBaseUrl}/get-students?${generateUrlParams(filter)}`;
export const deactivateStudentUrl = () =>
	`${studentBaseUrl}/deactivate-student`;
export const activateStudentUrl = () => `${studentBaseUrl}/activate-student`;
