import { formatDateFromAPI } from "../../utils/formatDate";
import { SAVE_PUTME_INFO } from "../constant";

export const putmeInitialState = (data) => ({
	nameRegNoConfirmation: {},
	passport: {
		passport: data?.passportAsBase64
	},
	programmeInfo: {
		regNo: data?.regNo,
		utmeScore: data?.programDetails?.utmeScore,
		utmeResultSlip: data?.programDetails?.resultSlip,
		firstSubject: {
			label: data?.programDetails?.firstSubject,
			value: data?.programDetails?.firstSubjectId
		},
		secondSubject: {
			label: data?.programDetails?.secondSubject,
			value: data?.programDetails?.secondSubjectId
		},
		thirdSubject: {
			label: data?.programDetails?.thirdSubject,
			value: data?.programDetails?.thirdSubjectId
		},
		fourthSubject: {
			label: data?.programDetails?.fourthSubject,
			value: data?.programDetails?.fourthSubjectId
		},
		faculty: {
			label: data?.programDetails?.faculty,
			value: data?.programDetails?.facultyId
		},
		...(data?.programDetails?.alternativeDepartment && {
			altDepartment: {
				label: data?.programDetails?.alternativeDepartment,
				value: data?.programDetails?.alternativeDepartment
			}
		}),
		department: {
			label: data?.programDetails?.department,
			value: data?.programDetails?.departmentId
		},
		departmentOption: {
			label: data?.programDetails?.departmentOption,
			value: data?.programDetails?.departmentOptionId
		},
		rrr: data?.programDetails?.rrr
	},
	personalInfo: {
		firstName: data?.personalInfo?.firstname,
		middleName: data?.personalInfo?.middlename,
		surName: data?.personalInfo?.surname,
		...(data?.personalInfo?.dateOfBirth && {
			dateOfBirth: formatDateFromAPI(data?.personalInfo?.dateOfBirth)
		}),
		...(data?.personalInfo?.gender && {
			sex: {
				label: data?.personalInfo?.gender,
				value: data?.personalInfo?.genderId
			}
		}),
		mobileNo: data?.personalInfo?.mobileNo,
		email: data?.personalInfo?.email,
		...(data?.personalInfo?.country && {
			country: {
				label: data?.personalInfo?.country,
				value: data?.personalInfo?.countryId
			}
		}),
		...(data?.personalInfo?.state && {
			state: {
				label: data?.personalInfo?.state,
				value: data?.personalInfo?.stateId
			}
		}),
		...(data?.personalInfo?.lga && {
			lga: {
				label: data?.personalInfo?.lga,
				value: data?.personalInfo?.lgaId
			}
		}),
		contactAddress: data?.personalInfo?.contactAddress,
		sponsorFullName: data?.personalInfo?.sponsorFullName,
		sponsorAddress: data?.personalInfo?.sponsorContactAddress,
		sponsorMobileNo: data?.personalInfo?.sponsorMobileNumber,
		...(data?.personalInfo?.sponsorRelationship && {
			sponsorRelationship: {
				label: data?.personalInfo?.sponsorRelationship,
				value: data?.personalInfo?.sponsorRelationshipId
			}
		}),
		rrr: data?.personalInfo?.rrr
	},
	oLevelResult: {
		sittings: data?.olevelInfo?.map((item) => ({
			...item,
			oLevelType: { value: item.oLevelTypeId, label: item.olevelType },
			examYear: { value: item.examYear, label: item.examYear },
			subjects: [
				...Object?.keys(item?.oLevelSubjectGradeNames).map(
					(key, index) => ({
						subject: {
							label: key?.toUpperCase(),
							value: Object?.keys(item?.oLevelSubjectGrade)?.[
								index
							]
						},
						grade: {
							label: item?.oLevelSubjectGradeNames?.[key],
							value: Object?.values(item?.oLevelSubjectGrade)?.[
								index
							]
						}
					})
				)
			]
		}))
	},
	uploadCertificate: {
		certificates: data?.certicateInfo?.map((certificate) => ({
			...(certificate.certificateType && {
				...certificate,
				certificateType: {
					value: certificate.certificateTypeId,
					label: certificate.certificateType
				}
			})
		}))
	}
});

export const putmeReducer = (state = {}, action) => {
	switch (action.type) {
		case SAVE_PUTME_INFO:
			return action.payload;
		default:
			return state;
	}
};
