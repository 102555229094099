export const postCourseRegistrationUrl = () => `courseRegistration/register`;

export const getCoursesForRegistrationUrl = ({
	sessionId,
	semester,
	yearOfStudyId
}) =>
	`courseRegistration/coursesforregistration?sessionId=${sessionId}&semester=${semester}&yearOfStudyId=${yearOfStudyId}`;

export const getRegisteredCoursesHistoryUrl = () =>
	`CourseRegistration/registeredcourseshistory`;

export const getBorrowCoursesUrl = ({
	sessionId,
	departmentId,
	semester,
	levelId,
	borrowLevelId,
	departmentOptionId
}) =>
	`CourseRegistration/borrowcourses?sessionId=${sessionId}&departmentId=${departmentId}&borrowLevelId=${borrowLevelId}&semester=${semester}&levelId=${levelId}${
		departmentOptionId ? `&departmentOptionId=${departmentOptionId}` : ""
	}`;

export const getRegisteredCoursesUrl = ({
	sessionId,
	semester,
	yearOfStudyId
}) =>
	`courseRegistration/registeredcourses?SessionId=${sessionId}&Semester=${semester}&YearOfStudyId=${yearOfStudyId}`;
