export const baseURL = process.env.REACT_APP_API_URL;

//these endpoint url's also serve as query keys
export * from "./urlCategories/Auth";
export * from "./urlCategories/Dashboard";
export * from "./urlCategories/StudentProfile";
export * from "./urlCategories/CourseRegistration";
export * from "./urlCategories/Results";
export * from "./urlCategories/Sessions";
export * from "./urlCategories/Enums";
export * from "./urlCategories/LecturerProfile";
export * from "./urlCategories/Department";
export * from "./urlCategories/PassportSignature";
export * from "./urlCategories/YearOfStudy";
export * from "./urlCategories/Payment";
export * from "./urlCategories/SchoolProgramme";
export * from "./urlCategories/Faculty";
export * from "./urlCategories/CourseManagement";
export * from "./urlCategories/AdmissionList";
export * from "./urlCategories/ResultManagement";
export * from "./urlCategories/RolesandClaims";
export * from "./urlCategories/Notices";
export * from "./urlCategories/SundryPayment";
export * from "./urlCategories/Application";
export * from "./urlCategories/MenuManagement";
export * from "./urlCategories/UserManagement";
export * from "./urlCategories/ApplicationInvoice";
export * from "./urlCategories/Select";
export * from "./urlCategories/ApplicationInvoice";
export * from "./urlCategories/PutmeApplication";
export * from "./urlCategories/DirectEntryApplication";
export * from "./urlCategories/PGApplication";
export * from "./urlCategories/JupebApplication";
export * from "./urlCategories/CountryStates";
export * from "./urlCategories/PartTimeApplication";
export * from "./urlCategories/Jamb";
export * from "./urlCategories/Webhook";


