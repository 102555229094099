import { generateUrlParams } from "../../utils/generateUrlParams";

const baseUrl = "RolesandClaims";

export const getRolesUrl = (filter) =>
	`${baseUrl}/roles?${generateUrlParams(filter)}`;

export const addRoleUrl = (filter) =>
	`${baseUrl}/create-role?${generateUrlParams(filter)}`;

export const deleteRoleUrl = () => `${baseUrl}/role`;

export const getAllClaimsUrl = () => `${baseUrl}/claims`;

export const getAllMenuClaimsUrl = () => `${baseUrl}/route-names`;

export const getRoleClaimsUrl = (filter) =>
	`${baseUrl}/role-claims?${generateUrlParams(filter)}`;

export const updateRoleClaimsUrl = () => `${baseUrl}/update-role-claims`;

export const getMenuClaimsUrl = (filter) =>
	`${baseUrl}/menu-claims?${generateUrlParams(filter)}`;

export const getUserClaimsUrl = (filter) =>
	`${baseUrl}/user-claims?${generateUrlParams(filter)}`;

export const deleteMenuClaimUrl = (id) =>
	`${baseUrl}/menu-claim?MenuClaimId=${id}`;

export const deleteUserClaimUrl = ({ userId, claim }) =>
	`${baseUrl}/delete-user-claim/${userId}/${claim}`;

export const createClaimUrl = () => `${baseUrl}/create-claim`;
export const updateUserClaimUrl = () => `${baseUrl}/update-user-claims`;
export const getStudentRolesUrl = () => `${baseUrl}/get-student-roles`;
