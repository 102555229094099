import { formatDateFromAPI } from "../../utils/formatDate";
import { SAVE_JUPEB_INFO } from "../constant";

export const jupebInitialState = (data) => ({
	rrr: "",
	passport: {
		passport: data?.passportUrl
	},
	personalInfo: {
		firstName: data?.firstname,
		middleName: data?.middlename,
		surName: data?.surname,
		...(data?.personalInfo?.dateOfBirth && {
			dateOfBirth: formatDateFromAPI(data?.personalInfo?.dateOfBirth)
		}),
		...(data?.gender && {
			sex: {
				value: data?.genderId,
				label: data?.gender
			}
		}),
		...(data?.religion && {
			religion: {
				value: data?.religionId,
				label: data?.religion
			}
		}),
		...(data?.bloodGroup && {
			bloodGroup: {
				value: data?.bloodGroupId,
				label: data?.bloodGroup
			}
		}),
		...(data?.genotype && {
			genotype: {
				value: data?.genotypeId,
				label: data?.genotype
			}
		}),
		mobileNo: data?.mobileNo,
		email: data?.email,
		...(data?.country && {
			country: {
				value: data?.countryId,
				label: data?.country
			}
		}),
		...(data?.state && {
			state: {
				value: data?.stateId,
				label: data?.state
			}
		}),
		...(data?.lga && {
			lga: {
				value: data?.lgaId,
				label: data?.lga
			}
		}),
		...(data?.course && {
			course: {
				label: data?.course,
				value: data?.courseId
			}
		}),
		...(data?.session && {
			session: {
				label: data?.session,
				value: data?.sessionId
			}
		}),
		...(data?.subjectCombination && {
			subjectCombination: {
				label: data?.subjectCombination,
				value: data?.subjectCombinationId
			}
		}),
		...(data?.department && {
			department: {
				label: data?.department,
				value: data?.departmentId
			}
		}),
		...(data?.faculty && {
			faculty: {
				label: data?.faculty,
				value: data?.facultyId
			}
		}),
		town: data?.town,
		permanentAddress: data?.permanentAddress,
		nokName: data?.nokName,
		nokAddress: data?.nokAddress,
		nokMobileNo: data?.nokMobileNo,
		...(data?.relationship && {
			nokRelationship: {
				label: data?.relationship,
				value: data?.relationshipId
			}
		})
	},
	oLevelResult: {
		sittings: data?.olevelInfo?.map((item) => ({
			...item,
			oLevelType: { value: item.oLevelTypeId, label: item.olevelType },
			examYear: { value: item.examYear, label: item.examYear },
			subjects: [
				...Object?.keys(item?.oLevelSubjectGradeNames).map(
					(key, index) => ({
						subject: {
							label: key?.toUpperCase(),
							value: Object?.keys(item?.oLevelSubjectGrade)?.[
								index
							]
						},
						grade: {
							label: item?.oLevelSubjectGradeNames?.[key],
							value: Object?.values(item?.oLevelSubjectGrade)?.[
								index
							]
						}
					})
				)
			]
		}))
	}
});

export const jupebReducer = (state = {}, action) => {
	switch (action.type) {
		case SAVE_JUPEB_INFO:
			return action.payload;
		default:
			return state;
	}
};
