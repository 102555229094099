import Avatar from "react-avatar";
import "./paymentTitle.css";
import { useCookies } from "react-cookie";
import { USER_ROLE_HOLDER } from "../../utils/constants";

export const PaymentTitle = ({ noMargin, details, secondDetails, user, className, paymentData }) => {
	const [cookies] = useCookies([
		USER_ROLE_HOLDER
	])

	const { [USER_ROLE_HOLDER]: userRole } = cookies
	return (
		<section
			className={`${noMargin ?? "mt-5"
				} personnel_card_align border border-bottom-0 ${className ? className : ""
				}`}
		>
			{
				userRole !== "student" &&
				<div className="row">
					<div className="col-md-3 d-flex align-items-center">
						<Avatar
							className="info-avatar"
							name={user?.fullName}
							size="225"
							src={user?.passPort}
							round={false}
							maxInitials={2}
							color="#00875a"
						/>
					</div>

					<div className="col-md-4 d-flex align-items-center">
						<div className="row">
							{details?.map((detail, index) => (
								<div key={index}>
									<div
										className="d-flex align-items-baseline info-container"
										key={index}
									>
										<p className="col-md-4">
											{`${detail.title}:`}{" "}
										</p>

										<p className="font-weight-bold col-md-8">
											{detail.value}
										</p>
									</div>
								</div>
							))}
						</div>
					</div>

					<div className="col-md-4 d-flex align-items-center gap-15">
						<div className="row">
							{secondDetails?.map((detail, index) => (
								<div key={index}>
									<div
										className="d-flex align-items-start info-container"
										key={index}
									>
										<p className="col-md-4">
											{`${detail.title}:`}{" "}
										</p>

										<p className="font-weight-bold col-md-8">
											{detail.value ?? "-"}
										</p>
									</div>
								</div>
							))}
						</div>
					</div>
					<div className="col-md-2" />
				</div>

			}

			<div className="container my-4 mx-0">
				<div className="row">
					<div className="col-md-4">
						<div className="card">
							<div className="card-body ">
								<p className="card-title">Total Amount to be Paid</p>
								<h1 className="d-flex align-items-end gap-2">{paymentData?.totalAmountToBePaid}  <span className="currency">NGN</span></h1>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="card">
							<div className="card-body">
								<p className="card-title">Total Amount Paid</p>
								<h1 className="d-flex align-items-end paid gap-2">{paymentData?.totalAmountPaid} <span className="currency">NGN</span></h1>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="card">
							<div className="card-body ">
								<p className="card-title">Total Due Amount</p>
								<h1 className="d-flex align-items-end unpaid gap-2">{paymentData?.totalDueAmount} <span className="currency">NGN</span></h1>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
