import { INITIAL_DATE } from "../../utils/constants";
import { PART_TIME } from "../constant";

export const partTimeInitialState = (data) => {
	return {
		Id: data?.id,
		passport: { passport: data?.passportUrl },
		Surname: data?.surname,
		Firstname: data?.firstname,
		Middlename: data?.middlename,
		...(data?.sessionId && {
			SessionId: {
				value: data?.sessionId,
				label: data?.session
			}
		}),
		...(data?.genderId && {
			GenderId: {
				value: data?.genderId,
				label: data?.gender
			}
		}),
		DateofBirth:
			data?.dateOfBirth === INITIAL_DATE ? "" : data?.dateOfBirth,
		...(data?.bloodGroupId && {
			BloodGroupId: {
				value: data?.bloodGroupId,
				label: data?.bloodGroup
			}
		}),
		...(data?.genoTypeId && {
			GenoTypeId: {
				value: data?.genoTypeId,
				label: data?.genoType
			}
		}),
		...(data?.countryId && {
			CountryId: {
				value: data?.countryId,
				label: data?.country
			}
		}),
		...(data?.stateId && {
			StateId: {
				value: data?.stateId,
				label: data?.state
			}
		}),
		...(data?.lgaId && {
			LgaId: {
				value: data?.lgaId,
				label: data?.lga
			}
		}),
		Town: data?.town,
		PermanentAddress: data?.permanentAddress,
		MobileNo: data?.mobileNo,
		Email: data?.email,
		...(data?.religionId && {
			ReligionId: {
				value: data?.religionId,
				label: data.religion
			}
		}),
		Disability: data?.disability,
		Hobby: data?.hobby,
		...(data?.courseId && {
			CourseId: {
				value: data?.courseId,
				label: data?.course
			}
		}),
		FormNo: data?.formNo,
		Completed: data?.completed,
		RRR: data?.rrr,
		SponsersSurname: data?.nextOfKin?.surname,
		SponsersFirstname: data?.nextOfKin?.firstname,
		SponsersMiddlename: data?.nextOfKin?.middlename,
		SponsersMobileNo: data?.nextOfKin?.mobileNo,
		SponsersAddress: data?.nextOfKin?.address,
		...(data?.nextOfKin?.relationshipId && {
			SponsersRelationship: {
				value: data?.nextOfKin?.relationshipId,
				label: data?.nextOfKin?.relationship
			}
		}),

		oLevelResult: {
			sittings: data?.olevelInfo?.map((item) => ({
				...item,
				subjects: [
					...Object?.keys(item?.subjectGrade).map((key, index) => ({
						subject: {
							label: key?.toUpperCase(),
							value: Object?.keys(item?.subjectGradeId)?.[index]
						},
						grade: {
							label: item?.subjectGrade?.[key],
							value: Object?.values(item?.subjectGradeId)?.[index]
						}
					}))
				]
			}))
		},
		InstitutionAttended: {
			InstitutionAttended: data?.institutionAttended?.map(
				(institutionAttended) => ({
					...(data?.institutionAttended && {
						NameOfInstitution:
							institutionAttended.nameOfInstitution,
						DateFrom: institutionAttended.dateFrom,
						DateTo: institutionAttended.dateTo,
						Certificate: institutionAttended.certificate
					})
				})
			)
		}
	};
};

export const partTimeReducer = (state = {}, action) => {
	switch (action.type) {
		case PART_TIME:
			return action.payload;
		default:
			return state;
	}
};
