import { generateUrlParams } from "../../utils/generateUrlParams";
const baseUrl = "ApplicationInvoice";

export const getApplicationInvoiceDataUrl = (filter) =>
	`${baseUrl}/application-invoice-data?${generateUrlParams(filter)}`;

export const generateAdmissionStatusInvoiceUrl = (regNo) =>
	`${baseUrl}/generate-admission-status-invoice?regno=${regNo}`;

export const generateApplicationInvoiceUrl = () =>
	`${baseUrl}/generate-application-invoice`;

export const getApplicationJupebFeeInvoiceDataUrl = (filter) =>
	`${baseUrl}/jupeb-fee-invoice-data?${generateUrlParams(filter)}`;

export const getApplicationReportsUrl = (filter) =>
	`${baseUrl}/payment-report?${generateUrlParams(filter)}`;

export const downloadApplicationReportsUrl = (filter) =>
	`${baseUrl}/download-payment-report?${generateUrlParams(filter)}`;

export const getApplicationInvoiceDetailsUrl = (rrr) =>
	`${baseUrl}/invoice-details?rrr=${rrr}`;

export const deactivateApplicationInvoiceUrl = (rrr) =>
	`${baseUrl}/deactivate-application-invoice?rrr=${rrr}`;
