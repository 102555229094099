import Avatar from "react-avatar";
import logo from "../../assets/images/logo.png";
import "./personnelCard.css";

export const PersonnelCard = ({ details, user, noMargin, noLogo }) => {
	return (
		<section className={`${noMargin ?? "mt-5"} personnel_card_align`}>
			{!noLogo && (
				<header className="d-flex justify-content-center mb-5">
					<img src={logo} alt={"logo"} className="personnel-image" />
				</header>
			)}
			<div className="row">
				<div className="col-3 d-flex align-items-center">
					<Avatar
						className="info-avatar"
						name={user?.fullName}
						size="160"
						src={user?.passPort}
						round={false}
						maxInitials={2}
						color="#00875a"
					/>
				</div>
				<div className="col-1" />

				<div className="col-8 d-flex align-items-center">
					<div className="row">
						{details?.map((detail, index) => (
							<div className="col-6" key={index}>
								<div className="d-flex align-items-center info-container">
									<p className="col-4">
										{`${detail.title}:`}{" "}
									</p>

									<p className="font-weight-bold col-8">
										{detail.value}
									</p>
								</div>
							</div>
						))}
					</div>
					{/* {details?.map((detail, index) => (
						<div
							className="d-flex align-items-center info-container"
							key={index}
						>
							<p className="col-md-3">{`${detail.title}:`} </p>

							<p className="font-weight-bold col-md-9">
								{detail.value}
							</p>
						</div>
					))} */}
				</div>
			</div>
		</section>
	);
};
