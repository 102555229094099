import { generateUrlParams } from "../../utils/generateUrlParams";

const baseUrl = "auth";

export const createAccountUrl = () => `${baseUrl}/CreateStudentAccount`;
export const loginUrl = () => `${baseUrl}/login`;
export const changePasswordUrl = () => `${baseUrl}/changePassword`;
export const resetPasswordUrl = () => `${baseUrl}/resetPassword`;
export const verifyUserUrl = () => `${baseUrl}/verifyUser`;
export const twoFactorAuthUrl = () => `${baseUrl}/two-factor-authentication`;
export const sendSetupEmailUrl = (userId, email) =>
	`${baseUrl}/send-setup-password-email?userId=${userId}&email=${email}`;
export const setupPasswordUrl = () => `${baseUrl}/setup-password`;
export const sendResetPasswordMailUrl = (email) =>
	`${baseUrl}/sendResetPasswordMail?email=${email}`;
export const initiateImpersonationProcessUrl = () => `${baseUrl}/impersonation`;
export const getImpersonatorDetailsUrl = ({
	userIdToImpersonate,
	impersonatorId,
	token
}) =>
	`${baseUrl}/impersonation-login?${generateUrlParams({
		userIdToImpersonate,
		impersonatorId,
		token
	})}`;
export const impersonateUserUrl = () => `${baseUrl}/impersonate`;
