import { generateUrlParams } from "../../utils/generateUrlParams";

const baseUrl = "Jupeb";

export const jupebLoadApplicationFormUrl = (rrr) =>
	`${baseUrl}/load-Jupeb-Application-form?rrr=${rrr}`;

export const jupebPersonalDetailsFormUrl = () =>
	`${baseUrl}/store-jupeb-application-details`;

export const jupebOLevelDetailsFormUrl = () =>
	`${baseUrl}/store-jupeb-olevel-details`;

	export const getJupebApplicationReportsUrl = (filter) =>
		`${baseUrl}/application-report?${generateUrlParams(filter)}`;
	
	export const downloadJupebApplicationReportsUrl = (filter) =>
		`${baseUrl}/download-report?${generateUrlParams(filter)}`;
