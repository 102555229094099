import { generateUrlParams } from "../../utils/generateUrlParams";

const baseUrl = "admin/result-management";

export const getResultsToManageUrl = (filter) =>
	`${baseUrl}/courses-assigned?${generateUrlParams(filter)}`;
export const getLecturerDepartmentsUrl = () =>
	`${baseUrl}/departments-dropdown`;
export const getSingleResultToManageUrl = (filter) =>
	`${baseUrl}/results?${generateUrlParams(filter)}`;
export const postResultApprovalUrl = () => `${baseUrl}/admin-approval`;
