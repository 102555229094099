import { generateUrlParams } from "../../utils/generateUrlParams";

const baseUrl = "Putme";

export const putmeLoadApplicationFormUrl = (jambRegNumber) =>
	`${baseUrl}/load-application-form?regNo=${jambRegNumber}`;

export const putmePersonalDetailsFormUrl = () =>
	`${baseUrl}/store-putme-application-personal-details`;

export const putmeCertificateDetailsFormUrl = () =>
	`${baseUrl}/store-putme-application-certificate-details`;

export const putmeOLevelDetailsFormUrl = () =>
	`${baseUrl}/store-putme-application-olevel-details`;

export const putmeProgrammeDetailsFormUrl = () =>
	`${baseUrl}/store-putme-application-programme-details`;

export const getPutmeApplicationReportsUrl = (filter) =>
	`${baseUrl}/application-report?${generateUrlParams(filter)}`;

export const downloadPutmeApplicationReportsUrl = (filter) =>
	`${baseUrl}/download-report?${generateUrlParams(filter)}`;
