import { generateUrlParams } from "../../utils/generateUrlParams";

const baseUrl = "PartTimeApplication";

export const partTimeVerifyApplicationFormUrl = (rrr) =>
	`${baseUrl}/verify-part-time-application-status/${rrr}`;

export const addAndUpdatePartTimeUrl = () =>
	`${baseUrl}/add-or-update-part-time-application`;

export const partTimePreviewUrl = (rrr) => `${baseUrl}/application/${rrr}`;

export const partTimeAddAndUpdateOlevelUrl = () =>
	`${baseUrl}/add-or-update-part-time-olevel`;

export const partTimeAddAndUpdateInstitutionUrl = () =>
	`${baseUrl}/add-or-update-part-time-institution`;

export const getPartTimeApplicationReportsUrl = (filter) =>
	`${baseUrl}/application-report?${generateUrlParams(filter)}`;

export const downloadPartTimeApplicationReportsUrl = (filter) =>
	`${baseUrl}/download-report?${generateUrlParams(filter)}`;
