import { formatDateFromAPI } from "../../utils/formatDate";
import { SAVE_STUDENT_DATA } from "../constant";

export const initialState = (data) => ({
	PersonalData: {
		Surname: data?.surname,
		Firstname: data?.firstname,
		Middlename: data?.othernames,
		...(data?.genderId && {
			Gender: {
				value: data?.genderId,
				label: data?.gender
			}
		}),
		DateOfBirth: formatDateFromAPI(data?.dateOfBirth),
		...(data?.countryId && {
			CountryId: {
				value: data?.countryId,
				label: data?.country
			}
		}),
		...(data?.stateId && {
			StateId: {
				value: data?.stateId,
				label: data?.state
			}
		}),
		...(data?.lgaId && {
			LgaId: {
				value: data?.lgaId,
				label: data?.lga
			}
		}),
		HomeTown: data?.homeTown ?? "",
		PermanentAddress: data?.permanentAddress,
		Email: data?.email,
		MobileNo: data?.mobileNo,
		ContactAddress: data?.contactAddress
	},
	ProgrammeDetail: {
		MatricNo: data?.regno,
		JambRegNo: data?.regno,
		DepartmentId: {
			value: data?.departmentId,
			label: data?.departmentName
		},
		DepartmentOptionId: {
			value: data?.departmentAreaOfSpecializationId,
			label: data?.departmentAreaOfSpecializationName ?? "NONE"
		},
		EntryYear: {
			value: data?.sessionId,
			label: data?.sessionName
		},
		StudentTypeId: {
			value: data?.studentTypeId,
			label: data?.studentTypeName
		},
		StudentModeOfEntryId: {
			value: data?.studentModeOfEntryId,
			label: data?.studentModeOfEntryName
		},
		StudentModeOfStudyId: {
			value: data?.studentModeOfEntryId,
			label: data?.studentModeOfEntryName
		}
	},
	NextOfKin: {
		Fullname: data?.nokFullName,
		Address: data?.nokContactAddress,
		PhoneNo: data?.nokMobileNumber,
		Email: data?.nokEmail,
		...(data?.nokRelationshipId && {
			Relationship: {
				value: data?.nokRelationshipId,
				label: data?.nokRelationship
			}
		}),
	},
	Passport: { Passport: data?.passport },
	Sponsor: {},
	MedicalRecords: [],
	isPassportValid: !!data?.passport,
	isPersonalDataValid: false,
	isProgrammeDetailValid: false,
	isSponsorValid: false,
	isNextOfKinValid: false
});

export const createStudentReducer = (state = {}, action) => {
	switch (action.type) {
		case SAVE_STUDENT_DATA:
			return action.payload;
		default:
			return state;
	}
};
