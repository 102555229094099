import { generateUrlParams } from "../../utils/generateUrlParams";

const baseUrl = "Payment";

export const getMyInvoicesUrl = (paymentType) =>
	`${baseUrl}/myInvoices?paymentType=${paymentType}`;
export const initiateSchoolFeesPaymentUrl = ({
	sessionId,
	paymentType,
	levelId
}) =>
	`${baseUrl}/schoolfees/initiatepayment?sessionId=${sessionId}&schoolFeesPaymentType=${paymentType}&levelId=${levelId}`;
export const initiateAcceptanceFeePaymentUrl = () =>
	`${baseUrl}/acceptance/initiatepayment`;
export const generateSchoolFeesInvoiceUrl = () =>
	`${baseUrl}/schoolfees/generateInvoice`;
export const generateSchoolFeesBalanceInvoiceUrl = () =>
	`${baseUrl}/schoolfees/generatebalanceInvoice`;
export const generateAcceptanceFeeInvoiceUrl = () =>
	`${baseUrl}/acceptance/generateInvoice`;
export const getInvoiceUrl = (rrr) => `${baseUrl}/invoice?rrr=${rrr}`;
export const getAcceptanceLetterUrl = () => `${baseUrl}/acceptanceletter`;
export const getSchoolFeesRecieptUrl = ({
	sessionId,
	yearofStudyId,
	schoolFeesPaymentType
}) =>
	`${baseUrl}/schoolfeesReceipt?sessionId=${sessionId}&yearofStudyId=${yearofStudyId}&schoolFeesPaymentType=${schoolFeesPaymentType}`;
export const getAllStudetInvoicesUrl = (filter) =>
	`${baseUrl}/allStudentInvoices?${generateUrlParams(filter)}`;
export const getAllStudetFeesUrl = (matricNo) =>
	`${baseUrl}/all-student-fees?matricNo=${matricNo}`;
export const getSchoolFeesAssignmentsUrl = (filter) =>
	`${baseUrl}/schoolfeesassignment?${generateUrlParams(filter)}`;
export const updateSchoolFeesAssignmentsUrl = () =>
	`${baseUrl}/updateschoolfeesassignment`;
export const getAcceptanceFeesUrl = (filter) =>
	`${baseUrl}/acceptancefeesassignment?${generateUrlParams(filter)}`;
export const updateAcceptanceFeesUrl = () =>
	`${baseUrl}/updateacceptancefeesassignment`;
export const deactivatefeeinvoiceUrl = () => `${baseUrl}/deactivatefeeinvoice`;
export const updateFeeInvoiceUrl = () => `${baseUrl}/schoolfees/updateinvoice`;
export const getPGFeeAmountUrl = (filter) =>
	`${baseUrl}/pg-fee-amount?${generateUrlParams(filter)}`;
export const generatePGSchoolFeesInvoiceUrl = () =>
	`${baseUrl}/pg-school-fees/generate-invoice`;
export const getPGFeeAssignmentsUrl = (filter) =>
	`${baseUrl}/pg-fee-assignment?${generateUrlParams(filter)}`;
export const updatePGFeesUrl = () => `${baseUrl}/pg-fee-assignment`;
export const getFeesReportUrl = (filter) =>
	`${baseUrl}/fee-report?${generateUrlParams(filter)}`;
export const downloadFeesReportUrl = (filter) =>
	`${baseUrl}/download-fee-report?${generateUrlParams(filter)}`;
export const getPGFeesReportUrl = (filter) =>
	`${baseUrl}/pg-fee-report?${generateUrlParams(filter)}`;
export const downloadPGFeesReportUrl = (filter) =>
	`${baseUrl}/download-pg-fee-report?${generateUrlParams(filter)}`;
export const getFeeVocherStatusUrl = (filter) =>
	`${baseUrl}/student-fee-voucher-status?${generateUrlParams(filter)}`;
export const byPassFeeVoucherUrl = () => `${baseUrl}/generate-voucher`;
export const getPGApplicationInvoiceDetailsUrl = (rrr) =>
	`${baseUrl}/pg-invoice-details?rrr=${rrr}`;
export const deactivatePGApplicationInvoiceUrl = (rrr) =>
	`${baseUrl}/deactivate-pg-invoice?rrr=${rrr}`;
export const payOnlineUrl = (rrr) => `${baseUrl}/pay-online?rr=${rrr}`;
export const getSchoolFeesStatusUrl = (filter) =>
	`${baseUrl}/get-school-fee-status?${generateUrlParams(filter)}`;
export const toggleSchoolFeesStatusUrl = () =>
	`${baseUrl}/toggle-schoolfee-status`;

export const getStudentLedgerUrl = (filter) =>
	`${baseUrl}/student-ledger?${generateUrlParams(filter)}`;