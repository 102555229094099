import styles from "./style.module.css";
export const AccessDenied = ({ title, text, icon, buttonGroup }) => {
	return (
		<div className={`container h-100`}>
			<div
				className={`row d-flex justify-content-center align-items-center h-100`}
			>
				<div className="col-12 col-sm-6">
					<div className={`${styles.text_box}`}>
						<h1 className={`mb-3 ${styles.error_page_title}`}>
							{title}
						</h1>
						<div className={`${styles.description} mb-4`}>
							<p
								className={`mb-3 lh-2 ${styles.error_page_text}`}
							>
								{text}
							</p>
						</div>

						<div className="d-flex align-items-center">
							{buttonGroup ?? <></>}
						</div>
					</div>
				</div>
				<div className={"col-12 col-sm-6 text-center"}>{icon}</div>
			</div>
		</div>
	);
};
