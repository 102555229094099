import { generateUrlParams } from "../../utils/generateUrlParams";

const baseUrl = "Results";

export const getLecturerCoursesUrl = ({ sessionId, semester }) =>
	`${baseUrl}/lecturercourses?sessionId=${sessionId}&semester=${semester}`;
export const getClassListUrl = (filter) =>
	`${baseUrl}/classlist?${generateUrlParams(filter)}`;
export const downloadClassListUrl = ({ departmentCourseId, sessionId }) =>
	`${baseUrl}/downloadClasslist?departmentCourseId=${departmentCourseId}&sessionId=${sessionId}`;
export const getResultsUrl = (filter) =>
	`${baseUrl}/get-course-grades?${generateUrlParams(filter)}`;
export const downloadScoreSheetUrl = ({ departmentCourseId, sessionId }) =>
	`${baseUrl}/downloadScoreSheet?departmentCourseId=${departmentCourseId}&sessionId=${sessionId}`;
export const uploadScoreSheetUrl = ({ departmentCourseId }) =>
	`${baseUrl}/uploadScoreSheet?departmentCourseId=${departmentCourseId}`;
export const getStudentCGPAsUrl = () => `${baseUrl}/studentCgpas`;
export const getStudentSemesterResultUrl = (sessionId, semester) =>
	`${baseUrl}/studentSemesterResult?sessionId=${sessionId}&semester=${semester}`;
export const getGradeSheetUrl = ({ departmentCourseId, sessionId }) =>
	`${baseUrl}/download-grade-sheet?departmentCourseId=${departmentCourseId}&sessionId=${sessionId}`;
export const getGSCourses = ({ levelId, semesterId }) =>
	`${baseUrl}/gs-courses?LevelId=${levelId}&SemesterId=${semesterId}`;
export const downloadGSScoreSheet = ({ sessionId, courseCode }) =>
	`${baseUrl}/download-gs-score-sheet?courseCode=${courseCode}&sessionId=${sessionId}`;
export const uploadGSScoreSheet = () => `${baseUrl}/upload-gs-score-sheet`;
export const getGSResultsUrl = (filter) =>
	`${baseUrl}/gs-result?${generateUrlParams(filter)}`;
export const downloadLecturerResultReport = (filter) =>
	`${baseUrl}/download-lecturers-results-report?${generateUrlParams(filter)}`;

export const getLecturerResultReport = (filter) =>
	`${baseUrl}/lecturers-results-report?${generateUrlParams(filter)}`;

export const getStudentCGPAsByIdUrl = (studentUserId) =>
	`${baseUrl}/student-semester-cgpa?studentUserId=${studentUserId}`;

