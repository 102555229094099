import { useMemo } from "react";
import { Route } from "react-router-dom";
import { TabLayout } from "../../ui_elements";

export const ApplicationReportsRouter = ({
	component,
	path,
	exact,
	purpose,
	title,
	user,
	...rest
}) => {
	const Component = component;
	const paths = useMemo(
		() => [
			{
				title: "Applications Payments Reports",
				path: "/app_reports/application_reports",
				disabled: false
			},
			{
				title: "PUTME App Reports",
				path: "/app_reports/putme_reports",
				disabled: false
			},
			{
				title: "JUPEB App Reports",
				path: "/app_reports/jupeb_reports",
				disabled: false
			},
			{
				title: "PG App Reports",
				path: "/app_reports/pg_reports",
				disabled: false
			},
			{
				title: "Part Time App Reports",
				path: "/app_reports/part_time_reports",
				disabled: false
			},
			{
				title: "DE App Reports",
				path: "/app_reports/de_reports",
				disabled: false
			}
		],
		[]
	);
	return (
		<Route
			exact={exact}
			path={path}
			{...rest}
			render={(props) => {
				return (
					<TabLayout title={title} paths={paths}>
						<Component {...rest} {...props} />
					</TabLayout>
				);
			}}
		/>
	);
};
