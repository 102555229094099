import { generateUrlParams } from "../../utils/generateUrlParams";

const baseUrl = "Department";

export const getDepartmentsUrl = (facultyId) =>
	`${baseUrl}${facultyId ? `?facultyId=${facultyId}` : ""}`;
export const getDepartmentOptionUrl = ({ departmentId }) =>
	`${baseUrl}/${departmentId}/departmentoptions`;
export const getAllHODsUrl = (filter) =>
	`${baseUrl}/all-hods?${generateUrlParams(filter)}`;
export const assignHODToDepartmentUrl = () =>
	`${baseUrl}/assignhodtodepartment`;
export const getJupebOptionsUrl = () => `${baseUrl}/all-jupebOptions`;
export const getJupebOptionsSubjectsUrl = (id) =>
	`${baseUrl}/all-jupebOptionsSubject?jupebOptionsId=${id}`;
export const getAllDepartmentActiveSessionsUrl = (filter) =>
	`${baseUrl}/get-all-depts-active-sessions?${generateUrlParams(filter)}`;
export const setDepartmentActiveSessionUrl = () =>
	`${baseUrl}/set-active-session`;
export const rollbackDepartmentActiveSessionUrl = () =>
	`${baseUrl}/rollback-active-session`;

export const getLevelCourseAdvisersUrl = (filter) =>
	`${baseUrl}/get-department-level-course-advisers?${generateUrlParams(
		filter
	)}`;

export const assignLevelCourseAdvisersUrl = () =>
	`${baseUrl}/assign-level-course-adviser`;
