import { generateUrlParams } from "../../utils/generateUrlParams";
const baseUrl = "DirectEntryApplication";

export const directEntryLoadApplicationFormUrl = (jambRegNumber) =>
	`${baseUrl}/confirm-direct-entry-application-status/${jambRegNumber}`;
export const postDirectEntryPersonalDetailsFormUrl = () =>
	`${baseUrl}/add-or-update-application`;
export const directEntryOLevelDetailsFormUrl = () =>
	`${baseUrl}/add-or-update-olevel`;
export const directEntryInstitutionAttendedUrl = () =>
	`${baseUrl}/add-or-update-institution`;
export const previewDirectEntryUrl = (jambRegNumber) =>
	`${baseUrl}/application/${jambRegNumber}`;
export const getDirectApplicationReportUrl = (filter) =>
	`${baseUrl}/application-report?${generateUrlParams(filter)}`;
export const downloadDirectApplicationReportUrl = (filter) =>
	`${baseUrl}/download-report?${generateUrlParams(filter)}`;
export const uploadDirectEntryListUrl = () =>
	`${baseUrl}/upload-direct-entry-list`;
export const getDirectEntryListUrl = (filter) =>
	`${baseUrl}/direct-entry-list?${generateUrlParams(filter)}`;
export const downloadDirectEntryListTemplateUrl = () =>
	`${baseUrl}/download-direct-entry-sample-sheet`;
