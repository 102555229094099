import { generateUrlParams } from "../../utils/generateUrlParams";

const baseUrl = "LecturerProfile";

export const getLecturerProfileUrl = () => `${baseUrl}/get-lecturer-profile`;
export const updateLecturerProfileUrl = () =>
	`${baseUrl}/UpdateLecturerProfile`;
export const getLecturersUrl = (filter) =>
	`${baseUrl}/lecturers?${generateUrlParams(filter)}`;
export const downloadLecturerListTemplateUrl = () =>
	`${baseUrl}/DownloadLecturerUploadSampleSheet`;
export const bulkUploadLecturerListUrl = () => `${baseUrl}/UploadLecturers`;
export const createNewLecturerUrl = () => `${baseUrl}/create-lecturer`;
export const singleLecturerEditUrl = (id) =>
	`${baseUrl}/UpdateLecturerProfile?UserId=${id}`;
export const downloadAdviserClassListUrl = (filter) =>
	`${baseUrl}/download-course-adviser-student-report?${generateUrlParams(
		filter
	)}`;
export const getLecturerCoursesUrl = (filter) =>
	`${baseUrl}/lecturer-courses-assigned?${generateUrlParams(filter)}`;

export const getAdviserClassListUrl = (filter) =>
	`${baseUrl}/get-course-adviser-student?${generateUrlParams(filter)}`;
